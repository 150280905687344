"use strict";
exports.__esModule = true;
var Initial = /** @class */ (function () {
    function Initial(callback, key) {
        this._initial = false;
        this._callback = callback;
        this._key = key;
    }
    Initial.prototype.call = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this._key && Initial.keys.has(_this._key))
                return resolve();
            if (!_this._initial) {
                _this._callback(function () {
                    _this._initial = true;
                    if (_this._key) {
                        Initial.keys.add(_this._key);
                    }
                    resolve();
                });
            }
            else
                resolve();
        });
    };
    /**
     * 重置
     */
    Initial.prototype.reset = function () {
        // 恢复初始
        this._initial = false;
        if (this._key) {
            Initial.keys["delete"](this._key);
        }
        // 执行并返回
        return this.call();
    };
    Initial.of = function (callback, key) {
        return new Initial(callback, key);
    };
    /**
     * 删除 key
     * @param {string} key key
     */
    Initial["delete"] = function (key) {
        Initial.keys["delete"](key);
    };
    /**
     * 校验 key, 已执行返回 true, 未执行返回 false
     * @param {string} key key
     */
    Initial.check = function (key) {
        return Initial.keys.has(key);
    };
    /**
     * 使用 key, 已执行返回 true, 首次执行返回 false, 执行后自动设置为已执行状态
     * @param {string} key key
     */
    Initial.use = function (key) {
        if (this.check(key)) {
            return true;
        }
        this.keys.add(key);
        return false;
    };
    Initial.keys = new Set();
    return Initial;
}());
exports["default"] = Initial;
